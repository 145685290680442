import { template as template_98b8650af18b4353889e23b0f91597be } from "@ember/template-compiler";
const WelcomeHeader = template_98b8650af18b4353889e23b0f91597be(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
