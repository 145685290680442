import { template as template_989235df31b041ffa63f37f860c4b1a2 } from "@ember/template-compiler";
const FKLabel = template_989235df31b041ffa63f37f860c4b1a2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
