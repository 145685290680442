import { template as template_b0f2fb19f3d0454195c974ade59bcc1c } from "@ember/template-compiler";
const FKText = template_b0f2fb19f3d0454195c974ade59bcc1c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
