import { template as template_15b6c3d8aeac4ba1a39e2820b8c0a5fc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_15b6c3d8aeac4ba1a39e2820b8c0a5fc(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
