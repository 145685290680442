import { template as template_ec1aa766a6fe42a180622afc9e192171 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_ec1aa766a6fe42a180622afc9e192171(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
