import { template as template_70681e74fdc348118da316dfb4dbdae9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_70681e74fdc348118da316dfb4dbdae9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
